import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePageContext } from 'common/components/base/pages/context';
import { marketplaceRequests } from 'modules/marketplace/services/marketplace.requests';
import { useHistory, useParams } from 'react-router';
import NotFound from 'routes/not-found';
import { useTranslation } from 'react-i18next';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import { orderItemUtils, orderUtils } from 'modules/marketplace/utils/marketplace.utils';
import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import { OrderItemStatusEnum, OrderStatusEnum } from 'common/enums';
import JudgmentView from './judgment-page-view';

const JudgmentPage = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { setTitle } = usePageContext();
    const { orderId } = useParams<{ orderId?: string }>();

    if (!orderId) {
        return <NotFound />;
    }

    const [isDisqualifyingProvider, setIsDisqualifyingProvider] = useState(false);
    const [isCreatingContract, setIsCreatingContract] = useState(false);

    const orderFilter = useMemo(
        () => ({
            orderId: Number(orderId),
        }),
        [orderId]
    );

    const {
        data: orderData,
        loading: loadingOrder,
        setData,
    } = usePaginatedQuery(marketplaceRequests.listOrdersByOrganization, orderFilter);
    const order = orderData?.data?.[0];

    const itemsToSummary = order?.items.filter(
        (item) => item.status !== OrderItemStatusEnum.FRUSTRATED
    );

    const handleCreateContract = useCallback(async () => {
        if (!orderId || isCreatingContract) return;

        try {
            setIsCreatingContract(true);

            const response = await marketplaceRequests.createContract({ orderId: Number(orderId) });

            if (response.status === 'success') {
                const updatedOrder = await marketplaceRequests.listOrdersByOrganization({
                    params: { orderId: Number(orderId) },
                });

                const unenforceabilityId = updatedOrder?.data?.[0]?.unenforceabilityId;

                addNotificationSuccess({
                    message: t('marketplace.contract-success-message'),
                });

                if (unenforceabilityId) {
                    history.replace(`/processos/${unenforceabilityId}?aba=documentos`);
                }

                if (!unenforceabilityId) {
                    history.replace(`/mercado/pedidos`);
                }
            }
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            setIsCreatingContract(false);
        }
    }, [orderId, orderData]);

    const handleDoDeclassifyProvider = useCallback(
        async ({ lotId, providerId }: { lotId: number; providerId: number }) => {
            if (!orderId || isDisqualifyingProvider) return;

            try {
                setIsDisqualifyingProvider(true);

                const response = await marketplaceRequests.doDeclassifyProvider({
                    orderId: Number(orderId),
                    lotId,
                    providerId,
                });

                if (response?.data?.length > 0) {
                    setData(response);
                    addNotificationSuccess({
                        message: t('marketplace.declassify-success-message'),
                    });
                }
            } catch (error) {
                addNotificationApiError(error);
            } finally {
                setIsDisqualifyingProvider(false);
            }
        },
        [orderId]
    );

    useEffect(() => {
        setTitle(t('term.judgment'));
    });

    useEffect(() => {
        if (order?.status === OrderStatusEnum.FINISHED) {
            history.replace(`/mercado/pedidos/empenho/${order.id}`);
        }
    }, [order]);

    const isJudged = order?.status !== OrderStatusEnum.OPENED;

    const hasInvalidOrderItem = order?.items.every(
        (item) => orderItemUtils.notFinished(item) && orderItemUtils.notFrustrated(item)
    );

    const isConfirmButtonDisabled =
        loadingOrder || isCreatingContract || hasInvalidOrderItem || orderUtils.notJudged(order);

    return (
        <JudgmentView
            order={order}
            itemsToSummary={itemsToSummary}
            loading={loadingOrder}
            onConfirm={handleCreateContract}
            onDeclassifyProvider={handleDoDeclassifyProvider}
            isDisqualifyingProvider={isDisqualifyingProvider}
            isConfirmButtonDisabled={isConfirmButtonDisabled}
            isJudged={isJudged}
        />
    );
};

export default JudgmentPage;
